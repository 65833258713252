import request from '@/utils/axios'

// 登录
export function Login(data) {
  return request({
    url: '/backapi/v1/user/session/login',
    method: 'POST',
    data
  })
}
function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  )
  var uuid = [],
    i
  radix = radix || chars.length
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    var r
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
// 注册
export function Register(data) {
  return request({
    url: '/backapi/v1/user/register',
    method: 'POST',
    data: {
      ...data,
      trackId: uuid(16, 16)
    }
  })
}

// 注销登录
export function Logout(params) {
  return request({
    url: '/backapi/v1/user/session/logout',
    method: 'GET',
    params
  })
}

// 修改密码
// export function ChangePassword(data) {
//   return request({
//     url: '/backapi/v1/user/password',
//     method: 'PUT',
//     data
//   })
// }

// 获取用户信息
export function GetUserInfo(params) {
  return request({
    url: '/backapi/v1/user/info',
    method: 'GET',
    params
  })
}
// 匿名短信发短信
export function sendAnonymous(data) {
  return request({
    method: 'POST',
    url: '/backapi/v1/message/send/anonymous',
    data,
  })
}
// 匿名短信发短信
export function messageHistoryPage(params) {
  return request({
    method: 'GET',
    url: '/backapi/v1/message/history/page',
    params,
  })
}
export function getMessageCode(code) {
  return request({
    method: 'GET',
    url: `/backapi/v1/message/decode/${code}`
  })
}

// 修改用户信息
export function SetUserInfo(data) {
  return request({
    url: '/backapi/v1/user/info',
    method: 'PUT',
    data
  })
}

// 取消订阅
export function Unsubscribe(data = {}) {
  return request({
    url: '/backapi/v1/user/cancel/subscription',
    method: 'PUT',
    data
  })
}

// 语音转文字
export function speechToText(data) {
  return request({
    url: '/backapi/v1/convert/speechToText2',
    method: 'post',
    data,
    custom: {
      headers: {
        website: 'recovery',
        phone_os: 2,
        apk_version_code: 10004
      }
    }
  })
}

// 获取路径
export function UploadFile(data) {
  return request({
    url: '/backapi/v1/convert/uploadFile',
    method: 'POST',
    data
  })
}
// 订单生成（勾选图片后点击“checkout”生成订单）
export const createOrder = async (data = {}) => {
  return request({
    url: '/order/create',
    method: 'post',
    data
  })
}
// 主流程结算时更新订单&扣除积分
export const payCredits = (data = {}) => {
  return request({
    url: `/order/payCredits/${data.uid}`,
    method: 'post',
    data
  })
}

//处理图片并更新到订单中
export const orderProcess = async (data = {}) => {
  return request({
    url: '/order/process',
    method: 'post',
    data
  })
}

// 根据uid获取订单信息
export const getByUid = async (params = {}) => {
  return request({
    url: '/order/getByUid',
    method: 'get',
    params
  })
}

// 图片处理
export function getPhotoInfo(params) {
  return request({
    url: '/backapi/v1/aiphoto/anime',
    method: 'get',
    params
  })
}
// 图片处理
export function getPhotoMattingInfo(params) {
  return request({
    url: '/backapi/v1/aiphoto/matting',
    method: 'get',
    params
  })
}

// 查询结果
export function GetResult(params) {
  return request({
    url: '/backapi/v1/convert/result',
    method: 'GET',
    params
  })
}

// 其他转PDF
export function DocToPDF(params) {
  return request({
    url: '/backapi/v1/pdf/fileToPdf',
    method: 'GET',
    params
  })
}
export function pdfToHtml(params) {
  return request({
    url: '/backapi/v1/pdf/pdfToHtml',
    method: 'GET',
    params
  })
}
export function pdfToImg(params) {
  return request({
    url: '/backapi/v1/pdf/pdfToImg',
    method: 'GET',
    params
  })
}
//其他文件格式转为PDF
export function fileConvert(params) {
  return request({
    url: '/backapi/v1/pdf/fileConvert',
    method: 'GET',
    params
  })
}
// PDF转为doc,docx,pptx,xlsx,rtf,txt
export function PDFToDoc(params) {
  return request({
    url: '/backapi/v1/pdf/pdfToDoc',
    method: 'GET',
    params
  })
}

// 获取转换结果
export function ObtainingResults(params) {
  return request({
    url: '/backapi/v1/pdf/getProgress',
    method: 'GET',
    params
  })
}

// 获取证件照详情
export function GetIdPhotoInfo(params) {
  return request({
    url: '/backapi/v1/pictureSetup/all',
    method: 'GET',
    params
  })
}

// 证件照制作
export function ProductionIdPhoto(data) {
  return request({
    url: '/backapi/v1/aiphoto/idPhoto',
    method: 'POST',
    data
  })
}


// 获取页面信息
export function getPageInfo(params) {
  return request({
    url: 'page',
    method: 'GET',
    params,
    custom: {
      baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_URL : '/nodeapi/', // prod
    }
  })
}

// 获取网站信息
export function getSiteInfo(params) {
  return request({
    url: 'site',
    method: 'GET',
    params,
    custom: {
      baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_URL : '/nodeapi/', // prod
    }
  })
}