<template>
  <div class="upload-wrap">
    <template v-if="!isSelected">
      <div class="type-select">
        <div
          :class="[
            'type-item',
            item.id == selectId.id ? 'active' : '',
            item.nodev ? 'type-item-default' : '',
          ]"
          :style="{ background: item.theme[0], borderColor: item.theme[1] }"
          v-for="item in typeList"
          :key="item.id"
          @click="selectType(item)"
        >
          <img class="type-img" :src="$getFile(item.img)" alt="" />
          <div class="type-name">{{ item.title }}</div>
        </div>
      </div>
      <div id="uploader" class="uploader">
        <img class="icon" :src="$getFile('conver-document.webp')" alt="" />
        <input
          ref="uploadInput"
          class="upload-input"
          type="file"
          :accept="selectId.convertType"
          @change="handleFiles"
        />
        <div class="up-btn">Click to select a file</div>
      </div>
    </template>

    <template v-else>
      <div class="loading-wrap">
        <div class="load-title">{{ this.selectId.title }}</div>
        <img :src="$getFile('ui/covertimg-all.webp')" alt="" />
        <div class="load">loading...</div>
      </div>
    </template>
  </div>
</template>

<script>
import { getUploadPic } from "@/common/uploading";
export default {
  props: {
    expirationTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectId: {
        id: 1,
        type: "docx",
        selectType: "pdf",
        convertType: ".pdf",
        title: "PDF to Word",
      },

      resultTypeItems: {
        pdf: "docx",
        doc: "pdf",
      },
      isSelected: false,
      acceptFileType:
        ".png, .jpg, .jpeg, .xlsx, .docx, .pptx, .pdf, .xls, .doc, .ppt",
      typeList: [
        {
          id: 1,
          type: "docx",
          convertType: ".pdf",
          selectType: "docx",
          img: "convert/icon-word.webp",
          title: "PDF to Word",
          theme: ["#F6FCFF", "#E2F1F9"],
        },
        {
          id: 3,
          type: "xlsx",
          convertType: ".pdf",
          selectType: "XLSX",
          img: "convert/icon-excel.webp",
          title: "PDF to Excel",
          theme: ["#F7FFFA", "#E2F5E9"],
        },
        {
          id: 5,
          type: "png",
          convertType: ".pdf",
          selectType: "PDF",
          nodev: true,
          img: "convert/icon-png.webp",
          title: "PDF to PNG",
          theme: ["#FBFAFF", "#EAE6FF"],
        },
        {
          id: 2,
          type: "docx",
          convertType: ".doc,.docx",
          selectType: "doc",
          img: "convert/convert-word.webp",
          title: "Word to PDF",
          nodev: true,
          theme: ["#F6FCFF", "#E2F1F9"],
        },
        {
          id: 4,
          type: "xlsx",
          convertType: ".xlsx, .xls",
          selectType: "XLSX",
          img: "convert/convert-excel.webp",
          title: "Excel to PDF",
          nodev: true,
          theme: ["#F7FFFA", "#E2F5E9"],
        },
        {
          id: 6,
          type: "pptx",
          convertType: ".ppt, .pptx",
          selectType: "ppt",
          img: "convert/convert-ppt.webp",
          title: "PPT to PDF",
          nodev: true,
          theme: ["#FFF8F7", "#F7E5E3"],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleFiles(e) {
      const files = e.target.files;
      this.upload(files);
    },
    // 选择类型
    selectType(item) {
      if (item.nodev) {
        return;
      }
      this.selectId = item;
    },
    // 获取文件 >>>> 2
    async upload(files, isClick) {
      if (!this.expirationTime) {
        this.$message({
          showClose: true,
          message:
            "You are not a member yet and cannot use this feature temporarily",
          type: "error",
        });
        return;
      }
      const file = files[0];
      // 提取文件类型
      const dotIdx = file.name.lastIndexOf(".");
      const type = file.name.substring(dotIdx + 1);
      let letter = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "g",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let [max, min] = [
        Math.floor(Math.random() * (10 - 7 + 1) + 1),
        Math.floor(Math.random() * (17 - 10 + 1) + 17),
      ];
      letter = letter
        .sort(() => 0.4 - Math.random())
        .slice(max, min)
        .slice(0, 8)
        .join("");
      this.fileName = file.name;
      //  假如文件类型允许上传
      if (this.acceptFileType.includes(type)) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("_model", "src");
        formData.append("type", type);
        this.uploadLoading = true;
        this.Selected();
        const pdfFile = await getUploadPic(file, "PDF"); // 上传文件
        this.getPdfCovery(pdfFile, this.selectId.type);
      } else {
        this.$message.error("fileTypeError");
      }
    },
    // 已选择 >>> 3
    Selected() {
      this.isSelected = true; // 控制切换窗口
    },
    getPdfCovery(pdfFile, type) {
      const data = pdfFile;
      let apiName;
      let params = { type, fileUrl: pdfFile };
      switch (Number(this.selectId.id)) {
        case 1:
          apiName = this.$api.PDFToDoc;
          break;
        case 2:
          apiName = this.$api.fileConvert;
          break;
        case 4:
          apiName = this.$api.fileConvert;
          break;
        case 6:
          apiName = this.$api.fileConvert;
          break;
        case 3:
          apiName = this.$api.PDFToDoc;
          break;
        case 5:
          apiName = this.$api.pdfToImg;
          params = {
            fileUrl: pdfFile,
            outType: 2,
          };
          break;
      }
      apiName(params)
        .then((result) => {
          let resultType = type;
          const getStatus = (uniqueId, resultType) => {
            this.$api
              .ObtainingResults({ uniqueId: uniqueId, resultType })
              .then((result) => {
                // console.log(result, 'get 结果')
                if (result.obj.progress !== 100) {
                  getStatus(uniqueId, resultType);
                } else {
                  this.isSelected = false;
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.download(result.obj.url, decodeURI(this.fileName));
                }
              });
          };
          getStatus(result.obj, resultType);
        })
        .catch((e) => {
          console.log(e, "eee>>>");
        });
    },

    download(blob, fileName) {
      // const url = URL.createObjectURL(blob)
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = blob;
      a.download = blob;
      // a.target = '_blank'
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // URL.revokeObjectURL(url)
    },
  },
};
</script>

<style scoped lang="scss">
.type-item-default {
  filter: grayscale(100%);
}
@media (min-width: 980px) {
  .upload-wrap {
    display: flex;
    align-items: center;
    margin-top: 26px;

    .type-select {
      width: 350px;
      height: 320px;
      margin-right: 10px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .type-item {
        position: relative;
        width: 125px;
        height: 100px;
        line-height: 20px;
        font-size: 14px;
        color: #666;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }

        .type-img {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
        }

        .type-name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }

      .active {
        background: #e5e5e5;
        border: 1px solid rebeccapurple;
        width: 123px;
        height: 98px;
      }
    }

    .uploader {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      position: relative;
      width: 100%;
      height: 314px;
      border-radius: 32px;
      border: 3px dashed rgba(102, 51, 153, 0.1);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;

      .icon {
        width: 120px;
        height: 100px;
        margin: 0 auto;
        margin-top: 50px;
      }

      .upload-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      .up-btn {
        width: 100%;
        height: 56px;
        line-height: 56px;
        color: #ccc;
        margin: 0 auto;
        font-size: 20px;
        margin-top: 20px;
        text-align: center;
        border-radius: 15px;
      }
    }

    .loading-wrap {
      height: 300px;
      margin: 0 auto;

      .load-title {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: bold;
        color: #05021e;
        line-height: 24px;
        margin-bottom: 12px;
      }

      img {
        width: 212px;
        height: 270px;
        animation: move 3s linear infinite;
      }

      .load {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: bold;
        color: #05021e;
        line-height: 24px;
        margin-top: 12px;
      }
    }
  }

  @keyframes move {
    0% {
      opacity: 0.5;
    }

    30% {
      opacity: 0.8;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 0.8;
    }

    100% {
      opacity: 0.5;
    }
  }
}

@media (max-width: 981px) {
  .upload-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0.26rem;

    .type-select {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 0.5rem;

      .type-item {
        position: relative;
        width: 2rem;
        height: 1.8rem;
        font-size: 0.25rem;
        color: #666;
        cursor: pointer;
        border-radius: 0.1rem;
        margin-bottom: 0.1rem;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }

        .type-img {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0.8rem;
          height: 0.8rem;
        }

        .type-name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }

      .active {
        background: #e5e5e5;
        border: 1px solid rebeccapurple;
        width: 1.96rem;
        height: 1.78rem;
      }
    }

    .uploader {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      position: relative;
      width: 98%;
      height: 2rem;
      border-radius: 0.2rem;
      border: 3px dashed rgba(102, 51, 153, 0.1);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;

      .icon {
        width: 1.2rem;
        height: 1rem;
        margin: 0 auto;
      }

      .upload-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      .up-btn {
        width: 100%;
        height: 0.56rem;
        line-height: 0.56rem;
        color: #ccc;
        margin: 0 auto;
        font-size: 0.3rem;
        text-align: center;
      }
    }

    .loading-wrap {
      height: 300px;
      margin: 0 auto;

      .load-title {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: bold;
        color: #05021e;
        line-height: 24px;
        margin-bottom: 12px;
      }

      img {
        width: 212px;
        height: 270px;
        animation: move 3s linear infinite;
      }

      .load {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: bold;
        color: #05021e;
        line-height: 24px;
        margin-top: 12px;
      }
    }
  }

  @keyframes move {
    0% {
      opacity: 0.5;
    }

    30% {
      opacity: 0.8;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 0.8;
    }

    100% {
      opacity: 0.5;
    }
  }
}
</style>