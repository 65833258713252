<template>
  <div class="card-animation-warp">
    <div class="card-animation-box">
      <div class="title" data-aos="fade-up">{{ content.title }}</div>
      <div class="user-appraise-warp">
        <div class="user-appraise-box" data-aos="fade-up">
          <div class="img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="46"
              viewBox="0 0 56 46"
              fill="none"
            >
              <path
                d="M54.5 2.36564L53.2529 9.6021C50.0106 9.40384 47.6412 9.93252 46.1447 11.1882C44.6482 12.4438 43.7337 14.129 43.4012 16.2438C43.0686 18.3585 42.9855 20.6385 43.1518 23.0837H54.5V44H33.1753V19.1185C33.1753 13.0386 35.0875 8.47862 38.9118 5.43865C42.8192 2.39869 48.0153 1.37435 54.5 2.36564ZM22.8247 2.36564L21.5776 9.6021C18.3353 9.40384 15.9659 9.93252 14.4694 11.1882C12.9729 12.4438 12.0584 14.129 11.7259 16.2438C11.3933 18.3585 11.3102 20.6385 11.4765 23.0837H22.8247V44H1.5V19.1185C1.5 13.0386 3.41216 8.47862 7.23647 5.43865C11.1439 2.39869 16.34 1.37435 22.8247 2.36564Z"
                fill="white"
              />
            </svg>
          </div>
          <transition name="fade">
            <div class="user-appraise-content" v-if="show">
              {{ selectItems.content }}
            </div>
          </transition>
          <div class="score-content">
            <div
              class="score-items"
              v-for="item in 5"
              :key="item"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="28"
                viewBox="0 0 30 28"
                fill="none"
              >
                <path
                  d="M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z"
                  fill="#FFDD66"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="user-list-box" data-aos="fade-up">
          <div
            class="user-items"
            v-for="(item, index) in content.userList"
            :key="index"
            :class="[{ active: index === checked }]"
            @mouseover="handleMouseOver(index)"
            @mouseout="handleMouseOut(index, true)"
          >
            <div class="user-img">
              <img loading="lazy" :src="$getFile(item.img.src)" />
            </div>
            <div class="user-name">{{ item.name }}</div>
            <div class="user-des">{{ item.des }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Appraise',
  props: {
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      checked: 0,
      show: true,
      checkedTimeOut: null,
    }
  },
  computed: {
    selectItems() {
      return this.content.userList.find((res, index) => index === this.checked)
    },
  },
  watch: {},
  mounted() {
    this.handleMouseOut(-1, true)
  },
  methods: {
    handleMouseOut(index, type) {
      this.checkedTimeOut = setInterval(() => {
        this.change(index + 1, type)
      }, 4000)
    },
    handleMouseOver(index) {
      this.change(index, false)
    },
    change(index, type = true) {
      if (index > this.content.userList.length - 1) {
        index = 0
      }
      this.show = false
      this.checked = index
      clearInterval(this.checkedTimeOut)
      if (type) {
        this.checkedTimeOut = setInterval(() => {
          this.change(index + 1, true)
        }, 4000)
      }
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s;
    opacity: 1;
    left: 0;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    right: -100%;
  }
  .card-animation-warp {
    background: #212431;
    padding: 90px 0;
    .card-animation-box {
      max-width: 1100px;
      margin: 0 auto;
      .title {
        color: #fff;
        text-align: center;
        font-family: Manrope;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 80px;
      }
      .user-appraise-warp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-appraise-box {
          max-width: 400px;
          display: flex;
          flex-direction: column;
          gap: 40px;
          .img {
            width: 56px;
          }
          .score-content {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .user-appraise-content {
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .user-list-box {
        display: grid;
        max-width: 536px;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 50px;
        column-gap: 40px;
        .user-items {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          opacity: 0.5;
          max-width: 200px;
          transition: all 0.5s;
          &.active {
            opacity: 1;
          }
          .user-img {
            width: 100px;
            margin-bottom: 20px;
            border-radius: 100%;
            img {
              width: 100%;
            }
          }
          .user-name {
            color: #fff;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .user-des {
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            line-height: normal;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s;
    opacity: 1;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .card-animation-warp {
    background: #212431;
    padding: 0.9rem 0.48rem;
    width: calc(100% - 0.96rem);
    .card-animation-box {
      margin: 0 auto;
      .title {
        color: #fff;
        text-align: center;
        font-family: Manrope;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.6rem;
      }
      .user-appraise-warp {
        .user-appraise-box {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          .img {
            width: 1.12rem;
          }
          .score-content {
            display: flex;
            align-items: center;
            gap: 0.04rem;
          }
          .user-appraise-content {
            color: #fff;
            font-family: Poppins;
            font-size: 0.32rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            min-height: 3.5rem;
          }
        }
      }
      .user-list-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0.56rem;
        column-gap: 0.5rem;
        margin-top: 0.8rem;
        .user-items {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          cursor: pointer;
          opacity: 0.5;
          max-width: 3.2rem;
          &:hover {
            opacity: 1;
          }
          &.active {
            opacity: 1;
          }
          .user-img {
            width: 1.5rem;
            margin-bottom: 0.4rem;
            border-radius: 100%;
            img {
              width: 100%;
            }
          }
          .user-name {
            color: #fff;
            font-family: Poppins;
            font-size: 0.32rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .user-des {
            color: #fff;
            font-family: Poppins;
            font-size: 0.24rem;
            margin-top: 0.04rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>