//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/views/index";
import SubIndex from "@/views/subIndex";
import board from "@/views/board";
import Protocol from "@/views/protocol";
import Product from "@/views/product";
import paymentResults from "@/views/paymentResults";
import Unsubscribe from "@/views/unsubscribe";

//注册路由组件
Vue.use(VueRouter);
//编写路由规则
const routes = [
  {
    path: "/",
    name: 'Home',
    component: Home
  },
  {
    path: "/sub/:id",
    name: 'SubIndex',
    component: SubIndex
  },
  {
    path: "/board/:id",
    name: 'board',
    component: board,
    meta: {
      noFrame: true,
    }
  },
  {
    path: "/protocol/:id",
    name: 'Protocol',
    component: Protocol
  },
  {
    path: "/payment-results",
    name: 'paymentResults',
    component: paymentResults
  },
  {
    path: "/t/:id",
    name: 'productList',
    component: Product
  },
  {
    path: "/unsubscribe",
    name: 'unsubscribe',
    component: Unsubscribe
  },
]
//创建路由
const router = new VueRouter({
  mode: 'history',
  routes
})
//导出
export default router;