<template>
  <!-- 顶部导航栏组件 -->
  <div class="nav_comtainer">
    <div
      :class="['nav_wrap', scrollFill ? 'shadow' : '']"
      ref="navBar"
      :style="{ background: scrollFill ? '#FFFFFF' : topEleBg }"
    >
      <div class="container row-between">
        <a class="nav_left" href="/">
          {{ content.title }}
        </a>
        <div class="flex">
          <div class="nav_right row">
            <div class="tools_menus row">
              <div class="tools_box" ref="toolsMenus">
                <div class="wrap row">
                  {{content.productsName||'Products'}}
                  <img
                    class="trangle_icon"
                    :src="$getFile('ui/icon-trangle.png')"
                    alt="icon-trangle"
                  />
                </div>
                <div class="convert_container">
                  <div class="convert_content row">
                    <div
                      class="convert_content_item"
                      v-for="(items, index) in navBarList"
                      :key="index"
                    >
                      <div class="convert_title">{{ items.title }}</div>
                      <div class="feature_list">
                        <div
                          class="feature_list_item row"
                          v-for="nod in items.list"
                          :key="nod.type"
                          @click="goTo(nod)"
                        >
                          <!-- <img loading="lazy" class="feature_item_img" :src="$getFile(nod.img)" alt=""> -->
                          <div class="feature_text">
                            {{beforeBrand}} {{ nod.title }}
                            <span class="tag" v-if="nod.tag">{{
                              nod.tag
                            }}</span>
                          </div>
                          <div class="feature_des" v-if="nod.des">
                            {{ nod.des }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tools_menus row">
              <div class="tools_box" ref="toolsMenus">
                <div class="row">
                  <kefu :hideKefu="content.hideKefu || false"  :open="content.openKefu"/>
                </div>
              </div>
            </div>
          </div>
          <div class="nav-right row">
            <a :href="content.fastLink" v-if="content.fastClick">{{
              content.fastClick
            }}</a>
            <login-wrap />
          </div>
        </div>
      </div>
    </div>

    <div class="h5_nav_bar">
      <mobile-nav-bar
        :scrollFill="scrollFill"
        :siteType="siteType"
        :navBarList="navBarList"
        :content="content"
        :afterBrand="afterBrand"
        :beforeBrand="beforeBrand"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/common/utils";
import DropDown from "./ui/DropDown.vue";
import kefu from "./ui/kefu.vue";

export default {
  name: "NavBar",
  components: { DropDown, kefu },
  data() {
    return {
      scrollFill: false,
      color: "",
      // 手机端代码支持
      showMobileHeader: false,
      // end
      showTools: false,
    };
  },
  props: {
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    siteType: {
      type: Number,
      default: 1,
    },
    afterBrand: {
      type: String,
      default: '',
    },
    beforeBrand: {
      type: String,
      default: '',
    },
    navBarList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    topEleBg() {
      return "#fff";
    },
  },
  watch: {
    topEleBg: {
      handler(val) {
        this.setFontColor(val);
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    // 支持手机端代码
    this.headerCtrl();
    window.addEventListener("resize", this.headerCtrl.bind(this));
  },
  mounted() {
    this.setFontColor(this.topEleBg);
    this.$store.commit("SAVE_LOGIN_INFO", this.content.loginInfo);
  },
  methods: {
    goTo(item) {
      this.showTools = false;
      if (Number(item.type) === Number(this.siteType)) {
        window.location.href = window.location.origin;
        return;
      }
      window.location.href = item.url;
    },
    handleLogin() {
      this.$store.commit("openDrawer", false);
      this.$store.commit("changeDialog", {
        name: "FormLayout",
        active: "Login",
      });
    },
    headerCtrl(e) {
      if (document.body.getBoundingClientRect().width <= 980) {
        this.showMobileHeader = true;
      } else {
        this.showMobileHeader = false;
      }
    },
    // val：navBar背景色，通过计算得出navbar文字深浅颜色
    setFontColor(val) {
      this.$nextTick(() => {
        let bg = utils.set16ToRgb(val);
        let r = 0;
        if (val.match(/#\w{6,8}/)) {
          r = parseInt(bg.substr(4, 3));
        }
        this.color = 255 - r > 100 ? "#FFFFFF" : "#011E15";
      });
    },
    handleScroll() {
      if (window.scrollY === 0) {
        this.scrollFill = false;
      } else {
        this.scrollFill = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$commonColor: "#8567F9"; //统一颜色
@mixin flex-around {
  display: flex;
  justify-content: space-around;
}

.shadow {
  box-shadow: 0 1px 5px 0 rgba(184, 184, 184, 0.5);
}

.flex {
  display: flex;
  align-items: center;
  gap: 100px;
}
@media (min-width: 981px) {
  .nav_comtainer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #efefef;
    .nav-right {
      position: relative;
      width: auto;
      height: 100%;
      @include flex-around;
      align-items: center;

      a {
        color: #000;
        margin-right: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .nav_wrap {
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: #020b22;
      line-height: 20px;
      padding: 0px 100px;
      height: 80px;
      box-sizing: border-box;

      z-index: 2;
      box-sizing: border-box;
      transition: all 0.3s ease;
      position: relative;
      .container {
        max-width: 1440px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav_left {
          color: #000;
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          white-space: nowrap;
          cursor: pointer;
          img {
            width: 143px;
            height: 36px;
            cursor: pointer;
          }
        }
        .nav_right {
          width: auto;
          height: 100%;
          display: flex;
          .login_btn {
            position: relative;
            overflow: hidden;
          }
          .tools_menus {
            height: 100%;
            .tools_box {
              display: flex;
              align-items: center;
              font-size: 16px;
              line-height: 25px;
              color: #05021e;
              padding: 0 30px;
              height: 100%;
              .wrap {
                cursor: pointer;
                .more_icon {
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                }
                .trangle_icon {
                  width: 10px;
                  height: 6px;
                  margin-left: 7px;
                  transition: all 0.3s ease;
                  transform: rotate(0deg);
                }
              }
              .convert_container {
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: white;
                box-sizing: border-box;
                border-top: 1px solid rgba(0, 40, 75, 0.1);
                box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                .convert_content {
                  justify-content: center;
                  margin: 0 auto;
                  padding: 28px 0 32px;
                  display: flex;
                  .convert_content_item {
                    &:not(:last-of-type) {
                      margin-right: 24px;
                      padding-right: 24px;
                      border-right: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    .convert_title {
                      color: #000;
                      font-size: 16px;
                      font-weight: bold;
                      line-height: 20px;
                      font-family: Poppins-Medium, Poppins;
                      text-align: left;
                      margin-left: 12px;
                    }
                    .feature_list {
                      margin-top: 12px;
                      display: grid;
                      grid-template-rows: repeat(2, 1fr);
                      grid-auto-flow: column;
                      column-gap: 40px;
                      row-gap: 16px;
                      .feature_list_item {
                        width: 188px;
                        padding: 8px 12px;
                        border-radius: 8px;
                        cursor: pointer;
                        border: 2px solid #fff;
                        &:hover {
                          border: 2px solid #e7e3fb;
                          background-color: #f4f2ff;
                        }
                        .feature_item_img {
                          width: 24px;
                          height: 24px;
                          margin-right: 10px;
                        }
                        .feature_text {
                          font-size: 16px;
                          color: #070707;
                          font-family: Poppins-Regular, Poppins;
                          // display: flex;
                          font-weight: bold;
                          .tag {
                            margin-left: 10px;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 900;
                            border-radius: 2px;
                            background: #f46868;
                            padding: 2px 5px;
                            text-transform: uppercase;
                          }
                        }
                        .feature_des {
                          font-size: 12px;
                          color: #333;
                        }
                      }
                    }
                  }
                }

                &::after {
                  position: absolute;
                  top: -80px;
                  right: 330px;
                  content: "0";
                  color: transparent;
                  width: 58%;
                  height: 80px;
                  background: transparent;
                }
              }

              &:hover .wrap {
                opacity: 0.7;
              }
              &:hover .trangle_icon {
                transform: rotate(-180deg);
              }
              &:hover .convert_container {
                opacity: 1;
                visibility: visible;
              }
              // .convert_container {
              //   opacity: 1;
              //   visibility: visible;
              // }
            }

            .tools_box_2 {
              position: relative;
              display: flex;
              align-items: center;
              font-size: 16px;
              line-height: 25px;
              color: #05021e;
              padding: 0 30px;
              height: 100%;
              .wrap {
                cursor: pointer;
                .more_icon {
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                }
                .trangle_icon {
                  width: 10px;
                  height: 6px;
                  margin-left: 7px;
                  transition: all 0.3s ease;
                  transform: rotate(0deg);
                }
              }
              .convert_container_2 {
                visibility: hidden;
                position: absolute;
                background-color: white;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                box-sizing: border-box;
                border-top: 1px solid rgba(0, 40, 75, 0.1);
                padding: 8px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
                border-radius: 8px;
                z-index: 999;
                .feature_list_2 {
                  display: grid;
                  grid-template-rows: repeat(2, 1fr);
                  grid-auto-flow: column;
                  column-gap: 40px;
                  row-gap: 16px;
                  .feature_list_item_2 {
                    width: 188px;
                    padding: 8px 12px;
                    border-radius: 8px;
                    cursor: pointer;
                    border: 2px solid #fff;
                    &:hover {
                      border: 2px solid #e7e3fb;
                      background-color: #f4f2ff;
                    }
                    .feature_item_img_2 {
                      width: 24px;
                      height: 24px;
                      margin-right: 10px;
                    }
                    .feature_text_2 {
                      font-size: 14px;
                      line-height: 21px;
                      color: #05021e;
                      font-family: Poppins-Regular, Poppins;
                    }
                  }
                }
              }
              &:hover .wrap {
                opacity: 0.7;
              }
              &:hover .trangle_icon {
                transform: rotate(-180deg);
              }
              &:hover .convert_container_2 {
                opacity: 1;
                visibility: visible;
              }
            }
          }
          button {
            padding: 7px 39px;
            font-size: 16px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            background: #000029;
            border-radius: 20px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .h5_nav_bar {
      display: none;
    }
  }
}
@media (max-width: 980px) {
  .nav_comtainer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1004 !important;
    .nav_wrap {
      display: none;
    }
    .h5_nav_bar {
      display: block;
    }
  }
}
</style>