<template>
  <!-- 主页为什么选择组件 -->
  <div class="whyChoose">
    <p class="title">{{ content.title }}</p>
    <p class="des">{{ content.des }}</p>
    <div class="why-area">
      <div v-for="(item, index) in content.resonList" :key="index" class="item">
        <p class="nums">{{ item.num }}</p>
        <p class="name">{{ item.des }}</p>
        <div class="line" />
        <p class="explain">{{ item.explain }}</p>
        <div v-if="item.isExclusive" class="exclusive">Exclusive</div>
      </div>
    </div>

    <div class="whyChoose-mobile">
      <div class="whyChoose-mobile-swiper-box" v-if="loading">
        <swiper :options="swiperOption" ref="swiper">
          <!-- <SwiperSlide v-for="(item, idx) in content.resonList" :key="idx">
            <div class="whyChoose-item">
              <p class="nums">{{ item.num }}</p>
              <p class="name">{{ item.des }}</p>
              <div class="line" />
              <p class="explain">{{ item.explain }}</p>
            </div>
          </SwiperSlide> -->
        </swiper>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyChoose',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      loading: false,
      swiperOption: {
        spaceBetween: -40,
        loop: true,
        loopAdditionalSlides: 1,
        slidesOffsetBefore: 25,
        slidesPerview: 2,
        slidesOffsetAfter: -30,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      options: {
        //导航前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //自动轮播图
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //无缝衔接
        loop: true,
        //分页器配置项
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
        //切换效果
        effect: "cube",
      },
    }
  },
  mounted() {

    this.$nextTick(() => {
      if (this.content) {
        setTimeout(() => {
          this.loading = true
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .whyChoose {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 120px;
    padding: 78px 0 100px;
    background: var(--theme-primary);

    .title {
      max-width: 1180px;
      margin-bottom: 12px;
      font-size: 36px;
      font-family: Poppins;
      color: var(--theme-primary-white);
      line-height: 56px;
      text-align: center;
    }

    .des {
      max-width: 1180px;
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-white);
      line-height: 24px;
      text-align: center;
    }

    .why-area {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      width: 1180px;
      margin-top: 42px;

      .item {
        position: relative;
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
        border-radius: 8px;
        overflow: hidden;
        transition: all .3s;

        &:hover {
          transform: translateY(-5px);
          -webkit-transform: translateY(-5px);
          -moz-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          -o-transform: translateY(-5px);
        }

        .nums {
          max-width: 320px;
          margin-bottom: 10px;
          font-size: 28px;
          font-weight: 500;
          font-family: Poppins-Medium, Poppins;
          color: var(--theme-primary-suffix);
          line-height: 24px;
        }

        .name {
          max-width: 320px;
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 500;
          font-family: Poppins-Medium, Poppins;
          color: var(--theme-primary-white);
          line-height: 22px;
          text-align: center;
        }

        .line {
          width: 80px;
          height: 2px;
          background: var(--theme-primary);
        }

        .explain {
          margin-top: 20px;
          max-width: 320px;
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          color: var(--theme-primary-black);
          line-height: 22px;
        }

        .exclusive {
          position: absolute;
          right: -30px;
          top: 20px;
          width: 126px;
          height: 30px;
          background: var(--theme-label);
          font-size: 16px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: var(--theme-primary-white);
          line-height: 30px;
          transform: rotate(45deg);
          text-align: center;
        }
      }
    }

    .whyChoose-mobile {
      display: none;
      overflow: hidden;
    }
  }
}

@media (max-width: 980px) {
  .whyChoose {
    width: 100%;
    margin-top: 1rem;
    padding: .72rem 0 .8rem;
    background: var(--theme-primary);

    .title {
      max-width: 6.86rem;
      margin: 0 auto .2rem;
      font-size: .48rem;
      font-family: Poppins;
      color: var(--theme-primary-white);
      line-height: .64rem;
      text-align: center;
    }

    .des {
      width: 6.86rem;
      margin: 0 auto;
      font-size: .28rem;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-white);
      line-height: .36rem;
      text-align: center;
    }

    .why-area {
      display: none;
    }

    .whyChoose-mobile {
      margin-top: .6rem;

      .whyChoose-mobile-swiper-box {
        .whyChoose-item {
          width: 5.5rem;
          height: 3.9rem;
          padding: .51rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.04rem 0.13rem 0rem #5c68821a;
          border-radius: 0.41rem;

          .nums {
            height: .82rem;
            margin-bottom: .17rem;
            font-size: .68rem;
            font-weight: 500;
            font-family: Poppins-Medium, Poppins;
            color: var(--theme-primary-suffix);
            line-height: .41rem;
          }

          .name {
            margin-bottom: .35rem;
            font-size: .28rem;
            font-weight: 500;
            font-family: Poppins-Medium, Poppins;
            line-height: .36rem;
          }

          .line {
            width: 1.36rem;
            height: .03rem;
            background: var(--theme-primary);
          }

          .explain {
            margin-top: .35rem;
            font-size: .28rem;
            font-family: Poppins-Regular, Poppins;
            color: var(--theme-primary-black);
            line-height: .36rem;
          }
        }
      }
    }

  }
}
</style>