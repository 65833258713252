<template>
  <div class="unsubscribe-warp">
    <div class="title">{{ $tname("title") }}</div>
    <div class="sub-title">
      {{ $tname("subtitle") }}
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      @submit.native.prevent="submitForm"
      label-position="top"
      class="form-warp"
    >
      <el-form-item label="Email address" prop="email">
        <el-input v-model="ruleForm.email" @keyup.enter="submitForm"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="btn" type="primary" @click="submitForm">{{
          $tname("btn")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$tname("tips"),
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: this.$tname("tips"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
  data() {
    return {
      jsonName: {
        en: {
          title: "Unsubscribe",
          subtitle:
            "Do you want to cancel your account? Confirm your email address to unsubscribe.",
          btn: "Submit",
          tips: "Please enter the correct email address",
        },
        id: {
          title: "Batal Langganan",
          subtitle:
            "Apakah Anda ingin membatalkan akun Anda? Konfirmasikan alamat email Anda untuk berhenti langganan.",
          btn: "Kirim",
          tips: "Silakan masukkan alamat email yang benar",
        },
      },
      ruleForm: {
        email: "",
      },
    };
  },
  methods: {
    $tname(title) {
      let localLang = localStorage.getItem("lang") || "en";
      return this.jsonName[localLang][title];
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const host = window.location.host.split('.')[1]
        window.location.href = `https://tryhelp.net?utm_source=${host}&email=${this.ruleForm.email}`;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: var(--theme-primary);
  color: var(--theme-primary-white);
  width: 100%;
}
@media (min-width: 981px) {
  .unsubscribe-warp {
    max-width: 600px;
    margin: auto;
    text-align: center;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .sub-title {
      font-size: 16px;
      color: #5f667a;
      margin-bottom: 30px;
    }
    .form-warp {
      width: 100%;
      :deep(.el-form-item__label) {
        width: 100%;
      }
    }
  }
}
@media (max-width: 980px) {
  .unsubscribe-warp {
    max-width: 80%;
    margin: auto;
    margin-top: 10vh;
    text-align: center;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 0.48rem;
      font-weight: bold;
      margin-bottom: 0.3rem;
    }
    .sub-title {
      font-size: 0.32rem;
      color: #5f667a;
      margin-bottom: 30px;
    }
    .form-warp {
      width: 100%;
      :deep(.el-form-item__label) {
        width: 100%;
      }
    }
  }
}
</style>