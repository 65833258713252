<template>
  <div class="payment-result-warp" v-loading="loading">
    <div class="payment-header-warp">{{ content.title }}</div>
    <div class="payment-header-box">
      <div class="header-box-top">
        <div class="logo">{{ siteCommon.appName }}</div>
        <template v-if="result.success && result.currency">
          <div class="title">{{ content.headerTitle }}</div>
          <div class="des">{{ content.headerDes }}</div>
        </template>
        <div class="line"></div>
      </div>
      <div class="header-box-bottom">
        <div class="title">{{ content.dearUser }}</div>
        <div
          class="des"
          v-for="(item, index) in content.userContent"
          :key="index"
          v-html="item"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { durationUnitShow, tryPrice, subPrice } from "@/utils/config";
export default {
  name: "paymentResults",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    content() {
      const tryMoney = tryPrice(this.result);
      const subMoney = subPrice(this.result);
      if (!this.result.success || this.result.status !== 1) {
        const headerDes = this.result.isRenew
          ? `${tryMoney},After the trial ends ${subMoney}.`
          : `You paid ${subMoney} Member service`;
        const headerTitle = this.result.isRenew
          ? `Member Subscription Service(${
              durationUnitShow[this.result.durationUnit]
            })`
          : `Member Service`;
        return {
          title: "Order payment failed.",
          headerTitle: headerTitle,
          headerDes: headerDes,
          dearUser: "Dear user:",
          userContent: [
            `Payment for your order failed. This could be due to many reasons, such as the network environment or your bank rejecting the transaction. Please don't be discouraged, we recommend you try again later. `,
            `If you have any other questions, please contact our online customer service staff, they will reply to your message as soon as possible.Thank you!`,
          ],
        };
      }

      const headerDes = this.result.isRenew
        ? `${tryMoney},After the trial ends ${subMoney}.`
        : `You paid ${subMoney} Member service`;
      const headerTitle = this.result.isRenew
        ? `Member Subscription Service(${
            durationUnitShow[this.result.durationUnit]
          })`
        : `Member Service`;
      return {
        title: "Thank you very much for ordering our service",
        headerTitle: headerTitle,
        headerDes: headerDes,
        dearUser: "Dear user:",
        userContent: [
          `Thank you very much for ordering our service. We have sent account and
          product information to your email <span style="color:blue;">(${this.result.email})</span
          >.`,
          `Please check the letters you have received recently. If you have any
          questions, please let us know through our customer service system.
          Thank you!`,
        ],
      };
    },
    siteCommon() {
      try {
        let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
        const content = sessionStorage.getItem("DefaultInfo")||'{}';
        let result = JSON.parse(content);
        if (result.en) {
          if (lang === "ph") lang = "en";
          result = result[lang] || result.en;
        }
        return {
          appName: result.NavBar.props.content.title,
        };
      } catch (error) {
        console.log(error);
        return {
          appName: "",
        };
      }
    },
    result() {
      if (!this.$route.query.oi) {
        return { success: false };
      }
      try {
        const result = atob(this.$route.query.oi);
        return Object.assign(JSON.parse(result), {
          success: true,
        });
      } catch (error) {
        return {
          success: false,
        };
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 981px) {
  .payment-result-warp {
    padding-bottom: 200px;
  }
  .logo {
    color: #000;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
  .payment-header-warp {
    max-width: 1580px;
    margin: 28px auto;
    margin-bottom: 60px;
    background: var(--theme-primary);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    padding: 90px 0;
  }
  .payment-header-box {
    width: 930px;
    padding: 40px 50px;
    border-radius: 16px;
    border: 1px solid #e6e6e6;
    margin: 0 auto;
    .header-box-top {
      .title {
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        margin-bottom: 10px;
        margin-top: 40px;
      }
      .des {
        color: rgba(0, 0, 0, 0.45);
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
      }
    }
    .line {
      width: 100%;
      height: 1px;
      position: relative;
      background: #e6e6e6;
      margin-top: 52px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        background: #292929;
        width: 110px;
      }
    }
    .header-box-bottom {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 24px;
      .title {
        color: #333;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .des {
        color: rgba(0, 0, 0, 0.45);
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
        span {
          color: blue;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .payment-result-warp {
    padding-bottom: 1rem;
  }
  .payment-header-warp {
    max-width: 7.2rem;
    margin: 0.28rem auto;
    margin-bottom: 0.6rem;
    background: var(--theme-primary);
    border-radius: 0.16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: Roboto;
    font-size: 0.48rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    padding: 0.9rem 0;
  }
  .payment-header-box {
    width: 6.1rem;
    padding: 0.4rem 0.5rem;
    border-radius: 0.16rem;
    margin: 0 auto;
    background: #f7f7f7;
    .header-box-top {
      .title {
        color: #000;
        font-family: Roboto;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
        margin-top: 0.4rem;
        margin-bottom: 0.1rem;
      }
      .des {
        color: rgba(0, 0, 0, 0.45);
        font-family: Roboto;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.28rem; /* 140% */
      }
    }
    .line {
      width: 100%;
      height: 1px;
      position: relative;
      background: #e6e6e6;
      margin-top: 0.52rem;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 0.02rem;
        background: #292929;
        width: 1.1rem;
      }
    }
    .header-box-bottom {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.24rem;
      .title {
        color: #333;
        font-family: Roboto;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
      }
      .des {
        color: rgba(0, 0, 0, 0.45);
        font-family: Roboto;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        span {
          color: blue;
        }
      }
    }
  }
  .logo {
    font-size: 0.32rem;
    text-overflow: ellipsis;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
  }
}
</style>