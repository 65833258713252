<template>
  <div>
    <div class="maks" v-loading="loading" v-if="loading"></div>
    <div
      v-for="(component, name) in components"
      :key="name"
      class="layout-items"
    >
      <component :is="component.component" v-bind="component.props"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      components: {},
      loading: true,
    };
  },
  methods: {
    setData(data) {
      let result = data.content;
      if (data) this.loading = false;
      let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
      if (lang === "ph") lang = "en";
      if (data.content.en) {
        result = data.content[lang] || data.content.en;
      }
      if (data.contentStatus !== 1) {
        // 遍历配置，动态引入和注册组件，并传递props
        for (const [name, config] of Object.entries(result)) {
          this.$set(this.components, name, {
            component: name,
            props: config.props,
          });
        }
      } else {
        console.log("暂无编写富文本展示逻辑 >>>");
      }
    },
  },
  async created() {
    try {
      // let siteInfo = sessionStorage.getItem("page_index");
      // if (siteInfo) {
      //   this.setData(JSON.parse(siteInfo));
      //   return;
      // }
      let host = this.$extractDomain(window.location.hostname);
      let result = await this.$api.getPageInfo({ host, page: "index" });
      sessionStorage.setItem("page_index", JSON.stringify(result.data));
      this.setData(result.data);
    } catch (error) {
      console.error("Failed to load component configuration:", error);
    }
  },
};
</script>
<style lang="scss">
.layout-items {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
