export default class Water {
  /**
   * options: {
   *   el: 父元素，
   *   column:  列数
   *   gap: 间距
   *   width: 子元素宽度
   * }
   * */
  constructor(options) {
    this.options = options
    this.init()
    this.render()
  }
  init() {
    this.childNodes = this.options.el.childNodes
    this.column = this.options.column
    this.gap = this.options.gap
    this.width = this.options.width
    this.heightList = []
  }
  render() {
    setTimeout(() => {
      [ ...this.childNodes ].forEach((value, idx) => {
        value.style.width = this.width + 'px'
        if (idx < this.column) {
          value.style.top = '0px'
          value.style.left = (this.width + this.gap) * idx + 'px'
          this.heightList.push(value.offsetHeight)
        }else {
          this.minIdx = this.getMinIdx()
          value.style.top = this.heightList[this.minIdx] + this.gap + 'px'
          value.style.left = (this.width + this.gap) * this.minIdx + 'px'
          this.heightList[this.minIdx] = this.heightList[this.minIdx] + value.offsetHeight + this.gap
        }
      })
    }, 200)
  }
  getMinIdx() {
    return this.heightList.indexOf(Math.min(...this.heightList))
  }
}