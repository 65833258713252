<template>
  <div class="dashboard-warp">
    <div class="left flex-1">
      <div class="title">{{ content.left.title }}</div>
      <div class="sub-title">{{ content.left.subTitle }}</div>
      <a :href="content.left.link" class="btn btn-primary" v-if="content.left.btn">{{ content.left.btn }}</a>
    </div>
    <div class="right flex-1">
      <div class="title">{{ content.right.title }}</div>
      <el-table :data="tableData" style="width: 100%" v-loading="loading" :empty-text="$t('tableStatus.empty')">
        <el-table-column prop="date" :label="content.right.tableForm.date">
        </el-table-column>
        <el-table-column prop="status" :label="content.right.tableForm.status">
          <template slot-scope="{row}">
            <template v-if="row.status === 0">
              {{ $t('tableStatus.padding') }}
            </template>
            <template v-if="row.status === 1">
              {{ $t('tableStatus.success') }}</template>
            <template v-if="row.status === 2">
              {{ $t('tableStatus.error') }}</template>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="message" :label="content.right.tableForm.status">
        </el-table-column> -->
      </el-table>
      <div class="pagination-box">
        <el-pagination layout="prev, pager, next" :current-page.sync="pagination.pageNumber" v-if="pagination.totalCount > 10" @current-change="init" :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  name: 'smsList',
  data() {
    return {
      loading: false,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
      },
      tableData: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$api
        .messageHistoryPage({ ...this.pagination })
        .then(res => {
          console.log('我在这里哇',res)
          this.pagination.totalCount = res.page.totalCount
          console.log('我在这里哇')
          this.tableData = res.page.result.map(ret => {
            return {
              date: dayjs(ret.createTime).format('YYYY-MM-DD HH:mm:ss'),
              status: ret.status,
              message: ret.message,
            }
          })
          console.log(this.tableData,"this.tableData>>>")
          this.loading = false
        })
        .catch(e => {
         console.log(e,"e>>>")
          this.loading = true
        })
    },
  },
  computed: {
    content() {
      return this.$t('dashboard')
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .dashboard-warp {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 60px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    min-height: calc(100vh - 300px);
  .flex-1{
   flex: 1;
  }
    .pagination-box {
      margin-top: 30px;
      text-align: right;
    }
    .left {
      .title {
        font-weight: bold;
        font-size: 30px;
        color: #000;
        margin-bottom: 30px;
      }
      .sub-title {
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
    .right {
      .title {
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 10px;
    font-size: 14px;
    border-radius: 6px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.btn-primary {
      color: #fff;
      font-weight: bold;
      background-color: var(--theme-primary-suffix);
      border-color: var(--theme-primary-suffix);
    }
  }
}
@media (max-width: 980px) {
  .dashboard-warp {
    padding: 0.6rem 0.2rem;
    min-height: 66vh;
    .pagination-box {
      margin-top: 0.1rem;
      text-align: right;
    }
    .left {
      .title {
        font-weight: bold;
        font-size: 0.4rem;
        color: #000;
        margin-bottom: 0.6rem;
      }
      .sub-title {
        font-size: 0.28rem;
        margin-bottom: 0.6rem;
      }
    }
    .right {
      margin-top: 1rem;
      .title {
        font-weight: bold;
        font-size: 0.4rem;
      }
    }
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.2rem 0.2rem;
    font-size: 0.28rem;
    border-radius: 0.12rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.btn-primary {
      color: #fff;
      font-weight: bold;
      background-color: var(--theme-primary-suffix);
      border-color: var(--theme-primary-suffix);
    }
  }
}
</style>
