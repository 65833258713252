<template>
  <div v-loading="loading" element-loading-text="Translating in progress, please wait a moment...">
    <div class="operate-warp">
      <div class="SpeechToText-warp">
        <el-upload class="upload-warp" ref="elupload" action="#" :file-list="fileList" :http-request="handleUpload"
          :on-remove="handleRemove" :before-remove="beforeAvatarUpload" multiple :limit="1" :on-exceed="handleExceed"
          accept=".wav,.mp3,.wma,.m4a,.amr,.mid,.ape,.flac,.ogg" drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drag the file here, or <em>Click to upload</em>
            <div class="el-upload__tip">Only the audio files can be uploaded</div>
          </div>
        </el-upload>
      </div>

      <div class="select-warp">
        <div class="title">Translate language</div>
        <div class="language-option">
          <div :class="['select-item', languageCode == item.value ? 'select-item-active' : '']"
            v-for="(item, index) in languageoptions" :key="index" @click="selectLanguage(item.value)">{{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="tips">
      Please make sure the audio format is wav, single-channel, 16kHz, and then try uploading again. If you need to convert the format, you can visit <a href="https://convertio.co">convertio</a> for conversion.

    </div>
    <div class="result-warp" v-show="languageResult">
      <div class="result-box" id="txt">
        {{ languageResult }}
      </div>
    </div>
    <div class="sub_btn">
      <div class="copy_btn" id="test" data-clipboard-action="copy" data-clipboard-target="#txt" @click="copyFun"
        v-show="languageResult">
        Copy
      </div>
      <div class="btn" @click="submitUpload">
        {{ languageResult ? 'Clean up' : 'Translation' }}
      </div>
    </div>
  </div>
</template>

<script>
//引入
import Clipboard from 'clipboard';

export default {
  name: "SpeechToText",
  props: {
    expirationTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languageCode: "en-GB",
      languageoptions: [
        {
          value: "en-GB",
          label: "English",
        },
        {
          value: "eu-ES",
          label: "Spanish",
        },
        {
          value: "fr-FR",
          label: "French",
        },
        {
          value: "it-IT",
          label: "Italian",
        },
        {
          value: "de-DE",
          label: "German",
        },
        {
          value: "ja-JP",
          label: "Japanese",
        },
        {
          value: "ko-KR",
          label: "Korean",
        },
        {
          value: "zh (cmn-Hans-CN)",
          label: "Chinese",
        },
        {
          value: "nl-NL",
          label: "Dutch",
        },
        {
          value: "et-EE",
          label: "Estonian",
        },
      ],
      languageResult: "",
      loading: false,
      fileList: [],
      fileInfo: ''
    };
  },

  methods: {
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "Processing, please wait a moment...",
      });
    },
    handleRemove(file, fileList) {
      this.loading = false;
      this.fileInfo = '';
      source.cancel("Operation canceled by the user.");
    },
    async submitUpload(tag) {
      if (this.languageResult) {
        return this.languageResult = ''
      }
      if (!this.fileInfo) {
        return this.$message({
          type: "warning",
          message: "Please add the files that need to be translated...",
        });
      }
      // 非会员不能使用此功能
      if (!this.expirationTime) {
        this.$refs.elupload.clearFiles()
        this.$message({
          showClose: true,
          message: 'You are not a member yet and cannot use this feature temporarily!',
          type: 'error'
        });
        return
      }
      this.loading = true;
      this.languageResult = "";
      await this.uploadBackend(this.fileInfo);
    },
    handleUpload(option) {
      this.fileInfo = option.file;
    },
    selectLanguage(lag) {
      this.languageCode = lag
    },
    async uploadBackend(file) {
      const formData = new FormData(); // 创建一个FormData对象
      formData.append("file", file); // 添加文件到FormData对象
      this.$api
        .UploadFile(formData)
        .then((result) => {
          this.convert(result.obj);
          this.fileInfo = ''
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    convert(fileKey) {
      this.$api
        .speechToText({ fileUrl: fileKey, languageCode: this.languageCode })
        .then((result) => {
          this.getResult(result.obj);
        });
    },
    getResult(uuid) {
      this.$api
        .GetResult({ uuid })
        .then((result) => {
          let time;
          if (result.success == "1") {
            this.languageResult = result.obj;
            this.loading = false;
            clearTimeout(time);
          } else if (result.success == "50014") {
            time = setTimeout(() => {
              this.getResult(uuid);
            }, 3000);
          } else {
            this.loading = false;
            
            this.$message({
            showClose: true,
            message: 'When attempting to convert the audio, please ensure to convert it to WAV format and set it to mono channel with a sampling rate of 16kHz.',
            type: 'error'
          });
          }
          this.$refs.elupload.clearFiles();
        })
        .catch((err) => { });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.warning(
          "You can only upload files up to 100MB in total,please select again."
        );
      }
      return isLt2M;
    },
    copyFun() {
      let clipboard = new Clipboard('#test');
      /* 注意此事件监听是异步的   */
      clipboard.on('success', (e) => {
        console.info('Text:', e.text);
        e.clearSelection();
        // 释放内存
        clipboard.destroy();
        // 复制成功
        this.$message({
          type: "success",
          message: "Replicating Success...",
        });
      });
      // 复制失败
      clipboard.on('error', (e) => {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tips{
  padding: 10px;
  font-size: 12px;
  color: #999;
}
::v-deep .el-loading-text {
  color: var(--theme-primary-suffix);
  margin: 3px 0;
  font-size: 14px;
}

::v-deep .el-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: var(--theme-primary-suffix);
  stroke-linecap: round;
}

@media (min-width: 980px) {

  .operate-warp {
    display: flex;
    justify-content: space-between;

    .SpeechToText-warp {
      position: relative;
      width: 680px;
      height: 360px;
      border-radius: 16px;
      border: 1px solid #e6e6e6;
      padding: 16px;
      box-sizing: border-box;

      .upload-warp {
        background: #fff;

        .el-upload__text {
          margin-top: 12px;
        }

        &::v-deep {
          .el-upload {
            width: 100%;
            text-align: left;
            margin-bottom: 12px;
          }



          .el-upload-list {
            .el-upload-list__item {
              display: flex;
              align-items: center;
              margin: 0;
              padding: 16px 0;
              border-bottom: 1px solid #dedede;
            }
          }

          .el-upload-dragger {
            width: 100%;
            height: 200px;
            border-radius: 16px;
          }
        }
      }
    }

    .select-warp {
      width: 400px;
      padding: 16px;
      border-radius: 16px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;

      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .language-option {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .select-item {
          width: 60px;
          padding: 6px 8px;
          margin-bottom: 10px;
          margin-right: 12px;
          color: #aaa;
          border-radius: 6px;
          border: 1px solid #e8e8e8;
          text-align: center;
          cursor: pointer;

          &:hover {
            border: 1px solid var(--theme-primary-suffix);
            color: var(--theme-primary-suffix);
          }
        }

        .select-item-active {
          width: 60px;
          padding: 6px 8px;
          margin-right: 12px;
          margin-bottom: 10px;
          font-weight: bold;
          color: var(--theme-primary-suffix);
          border: 1px solid var(--theme-primary-suffix);
          border-radius: 6px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .result-warp {
    margin-top: 24px;

    .result-box {
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #dedede;
      height: 158px;
      padding: 16px;
      border-radius: 16px;
    }
  }

  .sub_btn {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    text-align: right;
    margin-top: 24px;
    text-align: center;

    .copy_btn {
      width: 108px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      margin-right: 24px;
      font-family: Poppins-Medium;
      background: var(--theme-primary-suffix);
      color: #fff;
      cursor: pointer;
    }

    .btn {
      font-family: Poppins-Medium;
      width: 108px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      border: none;
      background: var(--theme-primary-suffix);
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

@media (max-width: 980px) {
  .operate-warp {
    margin-bottom: .24rem;

    .SpeechToText-warp {
      .upload-warp {
        position: relative;
        border-radius: .16rem;
        box-sizing: border-box;
        margin-bottom: .24rem;

        &::v-deep {
          .el-upload {
            width: 100%;
          }

          .el-upload-dragger {
            width: 100%;
            height: 2.8rem;
            border-radius: .16rem;
          }

          .el-upload-dragger .el-icon-upload {
            margin: .3rem 0 .16rem;
          }
        }
      }
    }

    .select-warp {
      padding: 0.2rem;
      border-radius: .16rem;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;

      .title {
        font-size: 0.30rem;
        font-weight: 500;
        margin-bottom: .32rem;
      }

      .language-option {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .select-item {
          padding: .12rem .14rem;
          font-size: .22rem;
          margin-bottom: .1rem;
          margin-right: .12rem;
          color: #aaa;
          border-radius: .03rem;
          border: 1px solid #e8e8e8;
          text-align: center;
          cursor: pointer;

          &:hover {
            border: 1px solid var(--theme-primary-suffix);
            color: var(--theme-primary-suffix);
          }
        }

        .select-item-active {
          font-weight: bold;
          color: var(--theme-primary-suffix);
          border: 1px solid var(--theme-primary-suffix);
          border-radius: .06rem;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .result-warp {
    margin-bottom: .5rem;

    .result-box {
      font-size: .16rem;
      font-weight: 500;
      border: 1px solid #dedede;
      padding: .16rem;
      border-radius: .16rem;
      min-height: 2.5rem;
    }
  }

  .sub_btn {
    width: 100%;
    display: flex;
    justify-content: end;

    .copy_btn {
      color: #fff;
      font-weight: bold;
      margin-right: .24rem;
      padding: 12px;
      font-size: .28rem;
      font-family: Poppins-Medium;
      border: none;
      border-radius: .1rem;
      background: var(--theme-primary-suffix);
      text-align: center;
      cursor: pointer;
    }

    .btn {
      color: #fff;
      font-weight: bold;
      padding: 12px;
      font-size: .28rem;
      font-family: Poppins-Medium;
      border: none;
      border-radius: .1rem;
      background: var(--theme-primary-suffix);
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
