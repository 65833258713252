<template>
  <div class="upload-wrap">
    <div class="title">Scan code to download app</div>
    <img :src="QRImgUrl" alt="" />
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  props: {
    url: {
      type: String,
      default:
        "https://sm-upload-usa.oss-cn-shanghai.aliyuncs.com/upload/app/Getmobi.apk",
    },
  },
  data() {
    return {
      QRImgUrl: "",
    };
  },
  mounted() {
    this.getQRcode(this.url);
  },
  methods: {
    getQRcode(linkUrl) {
      let opts = {
        errorCorrectionLevel: "L", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 4, //二维码留白边距
        width: 400, //宽
        height: 400, //高
        text: "", //二维码内容
        color: {
          dark: "#000000", //前景色
          light: "#fff", //背景色
        },
      };
      QRCode.toDataURL(linkUrl, opts, (err, url) => {
        if (err) throw err;
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url;
        // console.log(this.QRImgUrl);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .upload-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      color: #202124;
      font-family: Poppins-Medium;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 400px;
    }
  }
}

@media (max-width: 981px) {
  .upload-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      color: #202124;
      font-family: Poppins-Medium;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 3rem;
    }
  }
}
</style>