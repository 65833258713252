import { getOSSSignatureAi, getOSSSignaturePdf } from '@/api/oss';
import axios from 'axios';

export const getUploadPic = async (file, type) => {
  const result = await getOSSSignatureAi({ _model: 'src' })
  // console.log(result, file, "file >>>")
  const fileNames = file.name.split('.')
  const ext = fileNames[fileNames.length - 1]
  return await directUploadFile2OSS(result, file, generateUniqueId(), ext, type)
}

const directUploadFile2OSS = (ossSignature, file, filename, ext, type) => {
  return new Promise((resolve, reject) => {
    const date = new Date()
    const s = date.getTime()
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    // console.log(ossSignature, "ossSignature>>>")
    const ossFileKey =
      ossSignature.obj.dir + '/' + y + '/' + m + '/' + d + '/' + s + '_' + filename + '.' + ext

    // 添加配置参数
    const ossData = new FormData()
    ossData.append('OSSAccessKeyId', ossSignature.obj.accessid)
    ossData.append('policy', ossSignature.obj.policy)
    ossData.append('Signature', ossSignature.obj.signature)
    ossData.append('key', ossFileKey)
    ossData.append('success_action_status', 201) // 指定返回的状态码
    ossData.append('file', file, filename)
    axios.post(ossSignature.obj.host, ossData, {
      onUploadProgress: (progressEvent) => {
        // percentage = parseInt(progressEvent.loaded / progressEvent.total * 100)
      }
    })
      .then(result => {
        if (result.status === 201) {

          if (type === 'PDF') {
            let originalUrl = ossSignature.host + '/' + ossSignature.dir + '/' + y + '/' + m + '/' + d + '/' + s + '_' + filename
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(result.data, 'text/xml')
            const locationElement = xmlDoc.getElementsByTagName('Location')[0]
            let pdfFile = locationElement.textContent
            resolve(pdfFile)
          } else {
            const img = ossSignature.obj.host + '/' + ossFileKey
            resolve(img)
          }
        }
      }).catch(() => {
        reject()
      })
  })
}


function generateUniqueId() {
  const abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'g', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  const [max, min] = [
    Math.floor(Math.random() * (10 - 7 + 1) + 1),
    Math.floor(Math.random() * (17 - 10 + 1) + 17)
  ]
  return abc.slice(min, max).sort(() => 0.4 - Math.random()).slice(max, min).slice(0, 8).join('')
}
