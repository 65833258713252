<template>
  <div class="note-warp">
    <div class="note-book">
      <div class="title">
        <span class="fade-in fade-in-anim">{{ $t("smsBoard.title") }}</span>
      </div>
      <div class="note-box">
        <div class="note-title" ref="phone">
          {{
            $t("smsBoard.toPhone", {
              phone: content.phoneNumber,
            })
          }}
        </div>
        <div class="cntr" ref="cntr">
          {{ content.message }}
        </div>
        <div class="time">
          <div class="time-box" ref="time">{{ content.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TypeIt from "typeit";
import dayjs from "dayjs";
export default {
  name: "index-warp",
  data() {
    return {
      content: {
        phone: "+1 212-333-4444",
        content:
          "Send a discreet text message to anyone in the world.To whom have you al🥳 ways wanted to tell something anonymously? Your neighbor, your best friend, or your crush? This is your chance! The🥳  nice thing is that this person will never know who the message comes from🥳 . Unless you reveal yourself, of course ! 🥳 🥳 🥳 🥳 🥳 ",
        time: "2024/11/10 08:35",
      },
    };
  },
  mounted() {
    this.init(this.$route.params.id);
  },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async init(code) {
      if (!code) {
        return;
      }
      const { obj } = await this.$api.getMessageCode(code);
      this.content = {
        ...obj,
        time: dayjs(obj.createTime).format("YYYY-MM-DD HH:mm:ss"),
      };

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fade-in {
  opacity: 0;
}

/* Define the animation */
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to the text with a duration and timing function */
.fade-in-anim {
  animation: fadeInAnimation 0.5s ease-in-out forwards;
}
@media only screen and (min-width: 751px) {
  .note-warp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .note-book {
      width: 500px;
      margin: 0 auto;
      border-radius: 4px;
      border-radius: 32px;
      background: #fff;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.2);
      .note-box {
        padding: 0px 40px;
        .note-title {
          padding: 40px 0;
          height: 34px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.8);
          font-family: system-ui;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          padding-bottom: 20px;
          line-height: normal;
        }
      }
      .title {
        width: 100%;
        padding: 20px 0;
        text-align: center;
        color: #fff;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: #ffc745;
        border-radius: 32px 32px 0 0;
      }
      .cntr {
        /* 实现横线效果 */
        background: linear-gradient(transparent 98%, #e2e2e2 0);
        line-height: 30px;
        background-size: 100% 30px;
        background-attachment: local;
        resize: none;
        font-size: 14px;
        outline: none;
        border: none;
        overflow-x: hidden;
        min-height: 400px;
        color: rgba(0, 0, 0, 0.8);
        font-family: system-ui;
        font-size: 15px;
        font-style: normal;
        padding-bottom: 60px;
        padding: 0 10px;
        font-weight: 400;
        border-top: none;
      }
      .time {
        padding-top: 24px;
        padding-bottom: 40px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        justify-content: flex-end;
        .time-box {
          height: 24px;
          line-height: 24px;
          text-align: right;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .note-warp {
    padding: 25px 0;
    width: 100%;
    .note-book {
      width: 94%;
      margin: 0 auto;
      border-radius: 32px;
      background: #fff;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.2);
      .note-box {
        padding: 0px 20px;
        .note-title {
          padding: 30px 0;
          padding-bottom: 20px;
          height: 34px;
          line-height: 34px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.8);
          font-family: system-ui;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
        }
      }
      .title {
        width: 100%;
        padding: 20px 0;
        text-align: center;
        color: #fff;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: #ffc745;
        border-radius: 32px 32px 0 0;
      }
      .cntr {
        /* 实现横线效果 */
        background: linear-gradient(transparent 98%, #e2e2e2 0);
        line-height: 40px;
        background-size: 100% 40px;
        background-attachment: local;
        resize: none;
        font-size: 16px;
        outline: none;
        border: none;
        overflow-x: hidden;
        min-height: calc(60vh - 20px);
        color: rgba(0, 0, 0, 0.8);
        font-family: system-ui;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        padding: 0px 10px;
        padding-bottom: 60px;
      }
      .time {
        padding-top: 44px;
        padding-bottom: 40px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        justify-content: flex-end;
        .time-box {
          height: 24px;
          line-height: 24px;
          text-align: right;
        }
      }
    }
  }
}
</style>
