//  国家/货币
export const currencyMap = {
 CNY: '¥',
 USD: '$',
 PHP: '₱',
 ZAR: 'R',
 GBP: '£',
 SGD: 'S$',
 NZD: 'NZ$',
 EUR: '€',
 INR: '₹',
 CAD: 'C$',
 AUD: 'AUD',
 IDR: 'Rp',
 MYR: 'RM',
 KRW: '₩',
 HKD: 'HK$',
 TWD: 'NT$',
 BRL: 'R$',
 THB: '฿',
}
// 订阅单位展示 根据durationUnit
export const durationUnitShow = {
 1: "Hour",
 2: "Day",
 3: "Weekly",
 4: "Monthly",
 5: "Year",
 "lifetime": "Lifetime"
}
export const offerTypePrices = [
 'money',
 'tryoutPrice',
 'firstDiscountPrice'
]
//试用价格
export const tryPrice = (item) => {
 if (!item.currency) {
  return
 }
 return `${currencyMap[item.currency]} ${item[offerTypePrices[item.offerType]]}`
}
export const subPrice = (item) => {
 if (!item.currency) {
  return
 }
 if (item.isRenew) {
  return `${currencyMap[item.currency]} ${item.subscriptionPrice}/${durationUnitShow[item.durationUnit]}`
 }
 return `${currencyMap[item.currency]} ${item.subscriptionPrice} buy ${item.duration} ${(durationUnitShow[item.durationUnit]).replace('ly', '')}`
}
//duration 订阅单位，1个月 1个小时
//订阅单位展示 根据durationUnit
// en: {
//   1: "Hour",
//   2: "Day",
//   3: "Week",
//   4: "Month",
//   5: "Year",
//   "lifetime": "Lifetime"
// },
//订阅价格subscriptionPrice
//offerType试用订阅的字段，跟012相关
// enum offerTypePrices {
//  'showPrice',
//  'tryoutPrice',
//  'firstDiscountPrice'
// }