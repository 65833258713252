<template>
  <!-- 用户评论组件 -->
  <div class="discuss">
    <div class="content">
      <div class="discuss-title">{{ content.title }}</div>
      <div class="discuss-desc">{{ content.desc }}</div>
      <div class="discuss-list">
        <swiper ref="mySwiper" :options="swiperOption" class="mySwiper">
          <swiper-slide v-for="(items, idx) in commonsList" :key="idx" class="discusses">
            <div v-for="(item, index) in items" :key="index" class="discuss-item">
              <div class="discuss-item-header row">
                <img class="discuss-img" :src="$getFile(item.img)" alt="" />
                <div class="discuss-text-box">
                  <p>{{ item.name }}</p>
                  <img class="star" :src="$getFile('Discuss/star.webp')" alt="">
                </div>
              </div>
              <p class="commons">{{ item.des }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div class="docs" slot="pagination"><div v-for="(item, idx) in commonsList" :key="idx" class="doc" :class="{ 'act': idx === activeIndex }" /></div> -->
        <swiper ref="mySwiper1" :options="swiperOption1" class="mySwiper1">
          <swiper-slide v-for="(item, idx) in content.list" :key="idx">
            <div class="discuss-item">
              <div class="discuss-item-header row">
                <img class="discuss-img" v-if="item.img" :src="$getFile(item.img)" alt="" />
                <div class="discuss-text-box">
                  <p>{{ item.name }}</p>
                  <img class="star" :src="$getFile('Discuss/star.webp')" alt="">
                </div>
              </div>
              <p class="commons">{{ item.des }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div class="docs1"><div v-for="(item, idx) in content.list" :key="idx" class="doc" :class="{'act': idx === activeIndex1}" /></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscussComponents',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 10,
        loop: true,
        normalizeSlideIndex: true,
        loopAdditionalSlides: 3,
        slidesOffsetBefore: 25,
        slidesPerview: 2,
        slidesOffsetAfter: -30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 300000,
          disableOnInteraction: false
        },
      },
      swiperOption1: {
        spaceBetween: -40,
        loop: true,
        loopAdditionalSlides: 3,
        slidesOffsetBefore: 25,
        slidesPerview: 2,
        slidesOffsetAfter: -30,
        autoplay: {
          delay: 300000,
          disableOnInteraction: true
        },
      },
      commonsList: [],
      activeIndex: 0,
      activeIndex1: 0
    }
  },
  mounted() {
    for (let i = 0; i < this.content.list.length; i += 3) {
      this.commonsList.push(this.content.list.slice(i, i + 3))
    }
  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .discuss {
    width: 100%;
    background: var(--theme-primary);
    padding-top: 78px;
    padding-bottom: 100px;


    .content {
      max-width: 1180px;
      margin: 0 auto;
    }

    .discuss-title {
      font-size: 36px;
      font-family: Poppins;
      color: var(--theme-primary-black);
      line-height: 52px;
      text-align: center;
      margin-bottom: 12px;
      color: #FFFFFF;
    }

    .discuss-desc {
      color: #FFFFFF;
      text-align: center;
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      color: #FFFFFF;
      line-height: 24px;
    }

    .discuss-list {
      width: 1180px;
      margin: 0 auto;
      margin-top: 42px;

      .mySwiper1 {
        display: none;
      }

      .discusses {
        display: flex;
        justify-content: space-around;
      }

      .discuss-item {
        padding: 24px;
        width: 340px;
        height: 326px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px 0px rgba(166, 161, 219, 0.12);
        border-radius: 8px;
        box-sizing: border-box;

        .discuss-item-header {

          .discuss-img {
            width: 56px;
            margin-right: 8px;
          }

          p {
            font-size: 16px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #05021E;
            line-height: 24px;
            margin-bottom: 12px;
          }

          .star {
            width: 132px;
          }
        }

        .commons {
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #05021E;
          line-height: 22px;
          margin-top: 12px;
          cursor: default;
        }
      }

      .docs {
        display: flex;
        justify-content: center;
        margin-top: 36px;

        .doc {
          width: 8px;
          height: 8px;
          background: #FFFFFF;
          border-radius: 4px;
          margin: 0 2px;
        }

        .act {
          width: 18px;
          height: 8px;
          background: #FFFFFF;
          border-radius: 4px;
          opacity: 0.6;
        }
      }

      .docs1 {
        display: none;
      }
    }
  }
}

@media (max-width: 980px) {
  .discuss {
    background: linear-gradient(100deg, #98D5FE 0%, #9BB1FF 100%);
    padding: .72rem .32rem;
    text-align: center;

    .discuss-title {
      font-size: 0.48rem;
      font-family: Poppins;
      color: #FFFFFF;
      line-height: 0.64rem;
      margin-block: .24rem;
    }

    .discuss-desc {
      font-size: 0.28rem;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.36rem;
    }

    .discuss-list {
      width: 7.5rem;
      margin-left: -.32rem;
      margin-top: .6rem;

      .mySwiper {
        display: none;
      }

      .discusses {
        display: flex;
        justify-content: space-around;
      }

      .discuss-item {
        padding: .34rem .3rem .64rem;
        width: 6.46rem;
        height: 5.7rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.04rem 0.13rem 0rem rgba(92, 104, 130, 0.1);
        border-radius: 0.16rem;
        box-sizing: border-box;
        text-align: left;

        .discuss-item-header {

          .discuss-img {
            width: .8rem;
            margin-right: .16rem;
          }

          .discuss-text-box {

            p {
              font-size: 0.32rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #05021E;
              overflow: auto;
            }

            .star {
              width: 2.11rem;
            }
          }


        }

        .commons {
          font-size: 0.28rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #05021E;
          line-height: 0.38rem;
          margin-top: .3rem;
          cursor: default;
        }
      }

      .docs {
        display: none;
      }

      .docs1 {
        display: flex;
        justify-content: center;
        margin-top: .4rem;

        .doc {
          width: 0.16rem;
          height: 0.16rem;
          background: #FFFFFF;
          border-radius: 0.08rem;
          margin: 0 .04rem;
        }

        .act {
          width: 0.36rem;
          height: 0.16rem;
          background: #FFFFFF;
          border-radius: 0.08rem;
          opacity: 0.6;
        }
      }
    }


  }
}
</style>