<template>
  <!-- 首页banner 组件 -->
  <div class="sms-container">
    <div class="sms-box">
      <div class="sms-left">
        <div class="title">{{ content.title }}</div>
        <el-form
          class="form-box"
          ref="form"
          :rules="rules"
          label-position="top"
          :model="formLabelAlign"
        >
          <el-form-item :label="$t('form.sender')" class="form-item">
            <el-select
              v-model="formLabelAlign.sender"
              placeholder="Please select"
              class="select-warp"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('form.phoneNumber')"
            prop="phoneNumber"
            class="form-item"
          >
            <!-- <el-input
              v-model="formLabelAlign.recipient"
              placeholder="+1 5123 4567"
            ></el-input> -->
            <div class="el-input">
              <input
                class="el-input__inner"
                type="text"
                ref="phoneNum"
                v-model="formLabelAlign.phoneNumber"
              />
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('form.message')"
            class="form-item"
            prop="message"
          >
            <el-input
              v-model="formLabelAlign.message"
              type="textarea"
              placeholder="Write your message here"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              v-loading="loading"
              class="btn"
              type="success"
              @click="goPrice"
              >{{ content.btn }}</el-button
            >
            <div class="tips">
              <img src="@/assets/images/sms/verified.png" alt="" />
              <div class="tip-title">
                {{ content.tips }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="sms-right">
        <div
          class="sms-item"
          v-for="(item, index) in content.list"
          :key="index"
        >
          <img :src="$getFile(item.icon)" v-if="item.icon" class="icon" />
          <div class="sms-item-box">
            <div class="sms-item-title">{{ item.title }}</div>
            <div class="sms-item-subtitle">
              {{ item.des }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import IntlTelInput from "intl-tel-input";

export default {
  name: "Banner",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: "Send an anonymous SMS",
          btn: "Send now",
          tips: "verified Your information will remain secret from the recipient.",
          form: {
            sender: "Sender",
            recipient: "Recipient",
            message: "Message",
          },
          list: [
            {
              icon: "sms/sms-icon-1.svg",
              title: "100% anonymous",
              des: "To whom have you always wanted to tell something anonymously? Your neighbor, your best friend, or your crush? This is your chance! The nice thing is that this person will never know who the message comes from. Unless you reveal yourself, of course!",
            },
            {
              icon: "sms/sms-icon-2.svg",
              title: "Privacy",
              des: "Your privacy comes first. You don't have to leave any details to send an SMS and all information will be automatically removed from our system after a short time!",
            },
            {
              icon: "sms/sms-icon-3.svg",
              title: "Easy",
              des: "It's child's play! You determine the sender, the recipient, and the message. We do the rest. No registration required!",
            },
          ],
        };
      },
    },
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      expirationTime: false,
      options: [{
        value: 'Anonymous',
        label: 'Anonymous',
      },
      {
        value: 'Important',
        label: 'Important',
      },
      {
        value: 'Unknown',
        label: 'Unknown',
      },
      {
        value: 'Info',
        label: 'Info',
      },
        {
          value: 'X',
          label: 'X',
        },
      ],
      iti: null,
      rules: {
        phoneNumber: [
          {
            validator: this.validateCustomField,
            required: true,
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: this.$t("rules.message"),
            trigger: "change",
          },
          {
            validator: this.validateCustomMessageField,
            required: true,
            trigger: "change",
          },
        ],
      },
      formLabelAlign: {
        message: "",
        phoneNumber: "",
        sender: "Anonymous",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    validateCustomField(rule, value, callback) {
      // Implement your custom validation logic here
      if (
        !this.iti.isValidNumber() ||
        (this.formLabelAlign.phoneNumber.charAt(0) === "+" &&
          this.formLabelAlign.phoneNumber.replace(/\s/g, "") !==
            this.iti.getNumber())
      ) {
        this.phoneNumberChcked = false;
        callback(new Error(this.$t("rules.phone")));
      } else {
        this.phoneNumberChcked = true;
        callback();
      }
    },
    validateCustomMessageField(rule, value, callback) {
      // Implement your custom validation logic here
      if (this.formLabelAlign.message.length > this.maxLength) {
        this.showTips = false;
        callback(new Error(" "));
      } else {
        this.showTips = true;
        callback();
      }
    },
    getInfo() {
      this.$api.GetUserInfo().then((result) => {
        if (result.success === "1") {
          this.expirationTime =
            result.obj.expirationTime - new Date().getTime() > 0;
          if (!this.expirationTime) {
            this.$message({
              showClose: true,
              message: this.$t("messageTips.noVip"),
              type: "error",
            });
            this.$router.push("/sub/price");
            return;
          }
          this.sendMessage();
        }
      });
    },
    calculateByteUsage(str) {
      if (!str) {
        return {
          bytes: 0,
          characters: 0,
          smsNumber: 0,
        };
      }
      const byteLength = new TextEncoder().encode(str).length;
      var maxLengthPerSMS = 160;
      return {
        bytes: byteLength,
        characters: str.length,
        smsNumber: Math.ceil(byteLength / maxLengthPerSMS),
      };
    },
    sendMessage() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        const strLength = this.calculateByteUsage(this.formLabelAlign.message);
        this.formLabelAlign.senderType = this.options.find(
          (res) => res.label === this.formLabelAlign.sender
        ).value;
        this.$api
          .sendAnonymous({
            sender: this.formLabelAlign.sender,
            type: this.formLabelAlign.senderType,
            message: this.formLabelAlign.message,
            number: strLength.smsNumber,
            phoneNumber: this.iti.getNumber(),
            isScheduled: false, //定时器
            scheduledTime: 0,
          })
          .then((res) => {
            this.$message({
              showClose: true,
              message: this.$t("messageTips.sendSmsSuccess"),
              type: "success",
            });
            this.loading = false;
            this.$router.push("/sub/account");
          }).catch(e=>{
            console.log(e,"e>>>")
            this.$message({
              showClose: true,
              message: this.$t("messageTips.sendSmsErro"),
              type: "error",
            });
          });
      });
    },
    init() {
      this.formLabelAlign.recipient = "";
      this.iti = IntlTelInput(this.$refs.phoneNum, {
        initialCountry: "US",
        countrySearch: false,
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
      });
      const code = this.iti.getSelectedCountryData().dialCode;
      this.formLabelAlign.recipient = `+${code}`;
    },
    goPrice() {
      const userState = localStorage.getItem("user_email");
      if (!userState) {
        return this.$router.push("/sub/price");
      }
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 980px) {

.iti--fullscreen-popup .iti__country{
  font-size: 0.32rem;
}
}
</style>

<style scoped lang="scss">
.select-warp {
  width: 100%;
}

::v-deep .iti {
  width: 100%;
}
.el-input {
  width: 100%;
  input {
    width: 100%;
  }
}
@media (min-width: 981px) {
  .sms-container {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
    padding-top: 50px;
    .sms-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      .sms-left {
        flex: 0 0 460px;
        background-color: #f8f9fa;
        padding: 20px;
        .title {
          text-align: center;
          padding: 10px 0;
          font-weight: bold;
          font-size: 30px;
        }
        .form-item {
          ::v-deep .el-form-item__label {
            font-weight: bold !important;
          }
        }
        .btn {
          width: 100%;
          margin-top: 20px;
        }
        .tips {
          display: flex;
          gap: 10px;
          font-size: 14px;
          align-items: center;
          img {
            width: 20px;
          }
        }
      }
      .sms-right {
        display: flex;
        flex-direction: column;
        gap: 60px;
        .sms-item {
          display: flex;
          gap: 60px;
          align-items: start;
          .icon {
            width: 100px;
          }
          .sms-item-box {
            .sms-item-title {
              font-size: 20px;
              color: #343a40;
              font-weight: bold;
            }
            .sms-item-subtitle {
              margin-top: 10px;
              font-size: 16px;
              color: #343a40;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .sms-container {
    width: 100%;
    margin: 0 auto;
    .sms-box {
      width: 100%;
      .sms-left {
        background-color: #f8f9fa;
        // padding: 0.4rem;
        width: 100%;
        .title {
          text-align: center;
          padding: 0.2rem 0;
          font-weight: bold;
          font-size: 0.48rem;
        }
        .form-box {
          width: 80%;
          margin: 0 auto;
          .form-item {
            margin-bottom: 0;
            ::v-deep .el-form-item__label {
              padding: 0 !important;
              font-weight: bold !important;
            }
          }
        }
        .btn {
          width: 100%;
          margin-top: 0.2rem;
        }
        .tips {
          display: flex;
          gap: 0.2rem;
          font-size: 0.28rem;
          align-items: center;
          padding: 0.4rem 0.2rem;
          img {
            width: 0.4rem;
          }
          .tip-title {
            max-width: 6rem;
            line-height: normal;
          }
        }
      }
      .sms-right {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding: 0.2rem;
        .sms-item {
          display: flex;
          gap: 0.6rem;
          align-items: start;
          &:nth-child(even) {
            flex-direction: row-reverse;
          }
          .icon {
            width: 2rem;
          }
          .sms-item-box {
            .sms-item-title {
              font-size: 0.4rem;
              color: #343a40;
              font-weight: bold;
            }
            .sms-item-subtitle {
              margin-top: 0.2rem;
              font-size: 0.26rem;
              color: #343a40;
            }
          }
        }
      }
    }
  }
}
</style>