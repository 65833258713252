<template>
  <div class="account">
    <div class="profile">
      <div class="title">{{ content.profile }}</div>
      <div class="user-info">
        <div class="user-info-left">
          <img class="user-icon" :src="$getFile('user-icon.png')" alt="" />
          <div>
            <div class="email-title">{{$t('email')}}</div>
            <div class="email">{{ email }}</div>
          </div>
        </div>
        <div class="user-info-right">
          <div>{{ `Valid Until: ${formattedTime}` }}</div>
          <!-- <div>{{ expirationTime ? 'Subscription Plan' : 'Normal plan' }}</div> -->
          <div class="submit—mini" @click="handleUnsubscribe">
            {{ content.unsubscribe }}
          </div>
        </div>
      </div>

      <el-form ref="form" label-position="top" :inline="true" :model="form" label-width="80px">
        <el-form-item :label="content.labalList.firstName">
          <el-input v-model="form.firstName" />
        </el-form-item>
        <el-form-item :label="content.labalList.lastName">
          <el-input v-model="form.lastName" />
        </el-form-item>
        <el-form-item :label="content.labalList.address">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item :label="content.labalList.country">
          <el-select v-model="form.country" :placeholder="content.inputTip" style="width: 360px">
            <el-option v-for="(item, index) in contryList" :key="index" :label="item.name" :value="item.iso" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button class="submit" type="primary" :loading="loadStart" @click="onSubmit">
        {{ loadStart ? "" : content.submitBtn }}
      </el-button>
    </div>
    <div id="price" v-if="form.subStatus !==1" class="layout-item-box">
      <div v-for="(component, name) in components" :key="name">
        <component :is="component.component" v-bind="component.props"></component>
      </div>
    </div>

    <el-tabs v-model="activeName" class="last-wrap" v-if="showTbas">
      <el-tab-pane :label="txt.oldPictures" name="first">
        <UploadOldImg :expirationTime="expirationTime" />
      </el-tab-pane>
      <el-tab-pane :label="txt.passportPhoto" name="second">
        <UploadIdPhoto :expirationTime="expirationTime" />
      </el-tab-pane>
      <el-tab-pane :label="txt.voiceToTextResults" name="third">
        <SpeechToText :expirationTime="expirationTime" />
      </el-tab-pane>
      <el-tab-pane :label="txt.animeOriented" name="fourth">
        <UploadImg :expirationTime="expirationTime" />
      </el-tab-pane>
      <el-tab-pane :label="txt.onlinePDFConverter" name="fifth">
        <UploadFDF :expirationTime="expirationTime" />
      </el-tab-pane>
      <el-tab-pane :label="txt.downLoadingApp" name="sixth">
        <downLoadingApp :expirationTime="expirationTime" :url="content.appUrl" />
      </el-tab-pane>
    </el-tabs>
    <template v-else>
      <!-- 老照片 -->
      <div class="last-wrap" v-if="typeShow === '1'">
        <div class="title">Old Pictures</div>
        <UploadOldImg :expirationTime="expirationTime" />
      </div>
      <!-- 证件照 -->
      <div class="last-wrap" v-else-if="typeShow === '2'">
        <div class="title">Passport photo</div>
        <UploadIdPhoto :expirationTime="expirationTime" />
      </div>
      <!-- 语音转文字 -->
      <div class="last-wrap" v-else-if="typeShow === '3'">
        <div class="title">Voice To Text results</div>
        <SpeechToText :expirationTime="expirationTime" />
      </div>
      <!-- ai动漫化 -->
      <div class="last-wrap" v-else-if="typeShow === '4'">
        <div class="title">Anime oriented</div>
        <UploadImg :expirationTime="expirationTime" />
      </div>
      <!-- pdf -->
      <div class="last-wrap" v-else-if="typeShow === '5'">
        <div class="title">Online PDF Converter</div>
        <UploadFDF :expirationTime="expirationTime" />
      </div>
      <div class="last-wrap" v-else-if="typeShow === '6'">
        <downLoadingApp :expirationTime="expirationTime" :url="content.appUrl" />
      </div>
      <div class="last-wrap" v-else-if="typeShow === '7'">
        <smsList />
      </div>
    </template>
  </div>
</template>

<script>
import { contryList } from "@/common/countries.js";
import Loading from "./ui/Loading.vue";
export default {
  name: "TermsOfUse",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  computed: {
    txt() {
      return this.$t("account");
    }
  },
  components: { Loading },
  data() {
    let currenPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.content.errTip1));
      }
      callback();
    };
    let newPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.content.errTip1));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error(this.content.errTip2));
      } else {
        callback();
      }
    };
    let checkPassport = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.content.errTip3));
      } else if (value !== this.passForm.newPass) {
        callback(new Error(this.content.errTip4));
      } else {
        callback();
      }
    };
    return {
      activeName: 'second',
      showTbas: false,
      email: "",
      typeShow: "yyzwz",
      loadStart: false,
      expirationTime: false,
      subscriptionStatus: false,
      formattedTime: "1999-10-10",
      components: {},
      form: {
        firstName: "",
        lastName: "",
        address: "",
        country: "",
      },
      passForm: {
        currenPass: "",
        newPass: "",
        checkPass: "",
      },
      contryList,
      rules: {
        currenPass: [
          { required: true, validator: currenPass, trigger: "blur" },
        ],
        newPass: [{ required: true, validator: newPass, trigger: "change" }],
        checkPass: [
          { required: true, validator: checkPassport, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
    this.typeShow = sessionStorage.getItem("funType");
    if (this.content.component) {
      for (const [name, config] of Object.entries(this.content.component)) {
        this.$set(this.components, name, {
          component: name,
          props: config.props,
        });
      }
    }

    let localLang = localStorage.getItem("lang") || "";
    let lang = localLang || this.$route.query.lang || "en";
    if (lang === "ph") lang = "en";
    const lcalData = JSON.parse(sessionStorage.getItem('DefaultInfo'));
    if(lcalData[lang]){
    this.showTbas = lcalData[lang].NavBar.product
    }else{
      this.showTbas = lcalData.NavBar.product || false
    }
  },
  methods: {
    getInfo() {
      this.$api.GetUserInfo().then((result) => {
        if (result.success === "1") {
          this.form = result.obj;
          this.email = result.obj.email;
          localStorage.setItem("user_email", result.obj.email);
          this.subscriptionStatus = result.obj.subStatus;
          const now = new Date();
          this.expirationTime =
            result.obj.expirationTime - new Date().getTime() > 0;
          const date = new Date(result.obj.expirationTime);
          const year = date.getFullYear(); // 获取年份
          const month = String(date.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
          const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并补零
          this.formattedTime = `${year}-${month}-${day}`;
        } else {
          this.$api
            .Logout()
            .then((result) => {
              this.$store.commit("REMOVE_USER_INFO");
              if (this.$route.path !== "/") this.$router.push({ path: "/" });
            })
            .catch((err) => {
              // console.log(err, '失败 >>>')
            });
        }
      });
    },
    onSubmit() {
      this.loadStart = true;
      this.$api
        .SetUserInfo(this.form)
        .then((result) => {
          if (result.success === "1") {
            this.$message({
              message: this.content.suceeTip1,
              type: "success",
            });
            this.getInfo();
          }
        })
        .catch((err) => {
          if (err.msg) {
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loadStart = false;
        });
    },
    // 取消订阅
    handleUnsubscribe() {
      if (this.subscriptionStatus !== 1) {
        setTimeout(() => {
          if (document.querySelector(`#price`)) {
            document.querySelector(`#price`).scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        }, 500);
        return this.$message({
          message: "You did not subscribe to our service.",
          type: "warning",
        });
      }
      if (this.subscriptionStatus === 1) {
        this.$confirm("Do you confirm the unsubscription?", "tips", {
          confirmButtonText: "sure",
          cancelButtonText: "cancel",
          type: "warning",
        })
          .then(() => {
            this.$api
              .Unsubscribe({ reason: "User manually clicks" })
              .then((result) => {
                // if (result.success == 1) {
                this.$message({
                  message: "Unsubscribed successfully",
                  type: "success",
                });
                this.getInfo();
                // }
              })
              .catch((err) => {
                this.$message({
                  message: "Unsubscribed error",
                  type: "error",
                });
                // console.log(err, '>>>> err')
              });
          })
          .catch(() => {
            console.log("cancel");
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.layout-item-box {
  background: #fff;
  width: 100%;
  max-width: 1200px;
  padding: 40px 0;
}
@media (min-width: 981px) {
  .account {
    width: 100%;
    background: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
      width: 1200px;
      height: 461px;
      border-radius: 8px;
      background: #fff;
      margin-top: 40px;
      padding: 24px 48px;
      box-sizing: border-box;
      margin-bottom: 100px;
      margin-top: 100px;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .user-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 40px;

        .user-info-left {
          display: inline-flex;
          gap: 16px;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 24px;

          .user-icon {
            width: 42px;
          }
        }
      }

      .user-info-right {
        display: flex;
        align-items: end;
        flex-direction: column;
        color: #202124;
        font-family: Poppins-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        div {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .email-title {
        color: #202124;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        font-family: Poppins-Medium;
        line-height: normal;
      }

      .email {
        color: #adaeaf;
        font-size: 18px;
        font-weight: 400;
        margin-top: 5px;
      }

      ::v-deep .el-input {
        width: 360px;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 190px;
      }

      .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        font-family: Poppins-Medium;
        position: relative;
        width: 108px;
        height: 42px;
        border-radius: 4px;
        border: none;
        background: var(--theme-primary-suffix);
        line-height: 42px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .submit—mini {
        font-family: Poppins-Medium;
        height: 24px;
        padding: 0 10px;
        border-radius: 4px;
        background: var(--theme-primary-suffix);
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .submit-mini-los {
        font-family: Poppins-Medium;
        height: 24px;
        padding: 0 10px;
        border-radius: 4px;
        background: #565a59;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: no-drop;
      }
    }
    ::v-deep .el-tabs__header {
      margin-bottom: 30px;
    }

    .last-wrap {
      width: 1200px;
      border-radius: 8px;
      background: #fff;
      margin-top: -40px;
      padding: 24px 48px 30px;
      box-sizing: border-box;
      margin-bottom: 100px;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 50px;
      }
    }

    .password {
      width: 1200px;
      height: 444px;
      border-radius: 8px;
      background: #fff;
      margin-top: 40px;
      margin-bottom: 120px;
      padding: 24px 48px;
      box-sizing: border-box;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .explain {
        width: 927px;
        color: #adaeaf;
        font-family: Poppins-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;
        margin-bottom: 40px;
      }

      ::v-deep .el-input {
        width: 360px;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 190px;
      }

      .change-pass {
        margin-top: 10px;
        padding: 10px 28px;
        border-radius: 4px;
        background: var(--theme-primary-suffix);
        color: #fff;
        font-size: 14px;
        line-height: normal;
        text-transform: capitalize;
        display: inline-block;
        cursor: pointer;
        position: relative;
      }

      .curren-pass {
        ::v-deep .el-input__inner {
          padding-right: 100px;
        }

        ::v-deep .el-input__clear {
          margin-right: 70px;
        }
      }

      .curren-pass-input {
        position: relative;
      }

      .forgot {
        color: #416beb;
        font-family: Poppins-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        position: absolute;
        right: 16px;
        top: 10px;
        cursor: pointer;

        &:hover {
          color: #329678;
        }
      }
    }

    ::v-deep .el-form-item__label {
      color: #797b80;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 4px;
    }

    ::v-deep .el-input__inner {
      color: #202124;
      font-family: Poppins-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media (max-width: 980px) {
  .account {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
      border-radius: 0.08rem;
      background: #fff;
      margin-top: 0.34rem;
      box-sizing: border-box;
      margin-bottom: 0.8rem;
      padding: 0 0.25rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .user-info {
        margin-bottom: 0.5rem;

        .user-info-left {
          display: inline-flex;
          gap: 0.35rem;
          align-items: center;
          margin-top: 0.4rem;
          margin-bottom: 0.4rem;

          .user-icon {
            width: 42px;
          }

          .email {
            color: #adaeaf;
            font-size: 0.28rem;
            font-style: normal;
            font-family: Poppins-Medium;
            font-weight: 400;
            line-height: normal;
            margin-top: 0.08rem;
          }

          .email-title {
            color: #202124;
            font-family: Poppins-Medium;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .user-info-right {
          color: #202124;
          font-family: Poppins-Regular;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          text-align: left;

          div {
            margin-bottom: 0.24rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .submit—mini {
            margin-top: 10px;
            font-family: Poppins-Medium;
            position: relative;
            width: 96px;
            height: 24px;
            border-radius: 4px;
            background: var(--theme-primary-suffix);
            line-height: 24px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }

          .submit-mini-los {
            font-family: Poppins-Medium;
            width: 2rem;
            height: 0.5rem;
            padding: 0 0.1rem;
            border-radius: 0.1rem;
            background: #565a59;
            line-height: 0.5rem;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: no-drop;
          }
        }
      }

      ::v-deep .el-input {
        width: 6.9rem;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        width: 6rem;
        margin-right: 0;
      }

      .submit {
        width: 100%;
        height: 0.84rem;
        font-family: Poppins-Medium;
        color: #fff;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        position: relative;
        border: none;
        border-radius: 0.08rem;
        background: var(--theme-primary-suffix);
        text-align: center;
        cursor: pointer;
      }
    }

    ::v-deep .el-icon-arrow-left {
      margin-top: 0.8rem;
      &::before {
        font-size: 0.3rem;
        font-weight: bold;
      }
    }

    ::v-deep .el-icon-arrow-right {
      margin-top: 0.8rem;
      &::before {
        font-size: 0.3rem;
        font-weight: bold;
      }
    }

    .last-wrap {
      width: 100%;
      border-radius: 0.08rem;
      background: #fff;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-bottom: 1rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.38rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0.3rem;
      }
    }

    .password {
      width: 6.86rem;
      border-radius: 0.08rem;
      background: #fff;
      margin-top: 0.34rem;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-bottom: 0.63rem;

      .title {
        color: #202124;
        font-family: Poppins-Medium;
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .explain {
        color: #adaeaf;
        font-family: Poppins-Regular;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.1rem;
        margin-bottom: 0.5rem;
      }

      ::v-deep .el-input {
        width: 6.26rem;
      }

      ::v-deep .el-form-item__label {
        padding: 0;
      }

      ::v-deep .el-form-item {
        margin-right: 0;
      }

      .change-pass {
        width: 6.26rem;
        height: 0.84rem;
        margin-bottom: 0.2rem;
        font-family: Poppins-Medium;
        color: #fff;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.84rem;
        text-transform: capitalize;
        border-radius: 0.08rem;
        background: var(--theme-primary-suffix);
        text-align: center;
        position: relative;
      }

      .curren-pass {
        ::v-deep .el-input__inner {
          padding-right: 1.9rem;
        }

        ::v-deep .el-input__clear {
          margin-right: 1.3rem;
        }
      }

      .curren-pass-input {
        position: relative;
      }

      .forgot {
        color: #416beb;
        font-family: Poppins-Medium;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        position: absolute;
        right: 0.2rem;
        top: 0.16rem;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-form-item__label {
    color: #797b80;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.08rem;
  }

  ::v-deep .el-input__inner {
    color: #202124;
    font-family: Poppins-Regular;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

::v-deep .el-button--primary {
  background-color: #416beb;
  border-color: #416beb;

  &:hover {
    background-color: #329678;
    border-color: #329678;
  }
}
</style>