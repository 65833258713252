import request from '@/utils/axios'

// 获取oss上传地址 语音
// export function getOSSSignatureText(params) {
//   return request({
//     url: '/backapi/v1/oss/signature',
//     method: 'get',
//     params,
//     custom: {
//       headers: {
//         website: 'recovery',
//         phone_os: 2,
//         apk_version_code: 10004
//       }
//     },
//   })
// }

// 获取oss上传地址 ai
export function getOSSSignatureAi(params) {
  return request({
    url: '/backapi/v1/oss/aiphoto/signature',
    method: 'get',
    params,
    custom: {
      headers: {
        website: 'recovery',
        phone_os: 2,
        apk_version_code: 10004
      }
    },
  })
}

// 语音转文字
export function speechToText(data) {
  return request({
    url: '/backapi/v1/convert/speechToText',
    method: 'post',
    data,
    custom: {
      headers: {
        website: 'recovery',
        phone_os: 2,
        apk_version_code: 10004
      }
    },
  })
}

// 获取oss上传地址 pdf
export function getOSSSignaturePdf(params) {
  return request({
    url: '/backapi/v1/oss/pdf/signature',
    method: 'get',
    params,
    custom: {
      headers: {
        website: 'recovery',
        phone_os: 2,
        apk_version_code: 10004
      }
    },
  })
}